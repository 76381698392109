<template>
  <NuxtLink
    v-if="type === 'NuxtLink'"
    :to="to"
    :class="linkCssColor"
    class="arrow-link-icon icon-text is-flex-wrap-nowrap is-align-items-center is-flex-shrink-0"
  >
    <span
      :class="[iconCssClass, iconPosition === 'Left' ? 'has-pos-left' : 'has-pos-right']"
      class="icon"
    >
      <font-awesome-icon :icon="icon" />
    </span>
    <span>{{text}}</span>
  </NuxtLink>

  <a
    v-else-if="type === 'OnClick'"
    :class="[linkCssColor, iconPosition === 'Left' ? 'has-pos-left' : 'has-pos-right']"
    class="arrow-link-icon icon-text is-flex-wrap-nowrap is-align-items-center is-flex-shrink-0"
  >
    <span
      :class="iconCssClass"
      class="icon"
    >
      <font-awesome-icon :icon="icon" />
    </span>
    <span>{{text}}</span>
  </a>

  <a
    v-else
    :href="to"
    :target="newWindow ? '_blank': '_self'"
    :class="[linkCssColor, iconPosition === 'Left' ? 'has-pos-left' : 'has-pos-right']"
    class="arrow-link-icon icon-text is-flex-wrap-nowrap is-align-items-center is-flex-shrink-0"
  >
    <span
      :class="iconCssClass"
      class="icon"
    >
      <font-awesome-icon :icon="icon" />
    </span>
    <span>{{text}}</span>
  </a>
</template>

<script>
export default {
  name: 'IconLink',

  props: {
    iconPosition: {
      type: String,
      default: 'Left'
    },

    text: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: 'NuxtLink'
    },

    to: {
      type: String,
      default: ''
    },

    icon: {
      type: Array,
      default: function() {
        return ['fas', 'angle-right']
      }
    },

    iconCssClass: {
      type: String,
      default: ''
    },

    linkCssColor: {
      type: String,
      default: 'has-text-primary-dark'
    },

    newWindow: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style lang="sass" scoped>
.arrow-link-icon
  .has-pos-right
    order: 2
</style>